<template>
    <div class="m-wrapper03">
        <ul class="m-setting01">
            <li class="setting-con01">
                <span class="patient-name01">
                    张三
                    <!-- <em class="myself-box">(本人)</em> -->
                </span>
            </li>
            <li class="setting-con01">
                <span class="setting-lab02">医院</span>
                <input @click="chooseHospital" type="text" readonly placeholder="请选择医院" v-model="cardObj.name" class="setting-ipt01">
            </li>
            <li class="setting-con01">
                <span class="setting-lab02">卡号</span>
                <img v-show="type !== '2'" @click="addCardDesc" src="@/assets/images/ze-question.svg" alt="">
                <input type="text" placeholder="请输入卡号" v-model="cardObj.no" class="setting-ipt01">
                <!-- :readonly="type === '2'" -->
            </li>
        </ul>
        <div class="button-container">
            <a v-if="type == 2" @click="deleteCard">
                <div class="m-patient-but patient-add">删除</div>
            </a>
            <a @click="save">
                <div class="m-patient-but" style="margin: 0;">保存</div>
            </a>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
    data() {
        return {
            type: '',
            cardNumberVal: '',
            cardObj: {}
        }
    },
    computed: {
        ...mapState({
            // hospitalName: state => state.offlineApply.hospitalName,
            choosedHospitalName: state => state.layout.choosedHospitalName
        })
    },
    mounted() {
        this.type = this.$route.params['type']
        this.cardObj = {
            id: this.$route.query['id'],
            no: this.$route.query['no'] || this.cardNumberVal,
            name: this.$route.query['name'] || this.choosedHospitalName
        }
    },
    methods: {
        chooseHospital() {
            // 如果是查看就诊卡信息  那么就无法再选择医院
            // if(this.type === '2') return
            // 选择医院，跳转到医院选择页面
            this.$router.push('/offlineApply?from=choose')
        },
        deleteCard() {
            // 删除卡片
            this.$store.dispatch('deleteCardInfo', {
                id: this.id
            }).then(() => {
                this.$router.push('/manageCard')
            })
        },
        save() {
            // 保存新增就诊卡, 点击保存将医院信息置空
            this.$store.commit('setChoosedHospital', '')
            if(this.$route.query['from'] === 'add') {
                this.$router.push('/manageCard')
                return;
            }
            this.$router.push('/record')
        },
        addCardDesc() {
            // 查看添加卡
            this.$router.push('/cardDesc')
        }
    }
}
</script>
<style lang="scss" scoped>
$mainColor: #00C2C3;
.m-wrapper03 {
    height: 100vh;
    background: #ffff;

    .patient-name01 {
        color: #333;
    }
}

.button-container {
    display: flex;
    justify-content: space-evenly; 
    margin-top: 40px;
}
.setting-lab02 {
    width: auto;
    text-align: left;
}

.setting-ipt01 {
    text-align: right;
    margin-right: 10px;
}

.m-patient-but {
    background-color: $mainColor;

    &.patient-add {
        background-color: #f1f1f1;
    }
}
</style>